import { Redirect } from 'react-router-dom';



const Landing = () => {
    return (
        <Redirect to='/login'></Redirect>
    )
}

export default Landing
